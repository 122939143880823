import "../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VyW7bMBC95yt4KVAfGMjOUoe59UcKmhxJk1KkSo4iO0X+vRBpxaIsZwF6nfXNvMfh9a/1nwdonqhgf68YKw3seeVdL9j68YoxjaE18iCiYzA8dYGwPHDlLIGl5OBg9eBs0PIeNdWCre+KNiY0cj/aNsn2enU9Nl3HpjupflfedVYL1tdIMOTlOalODVjVJNhN8VwPNvcMvjSu5wfBZEcuq72JtQn2xMlLG0rnG8GUbJGkwZfYpHSWeH+sel8U540nBW9iwbONxAVo9KAInRVMOdM1NkH2FVq+c0SuEWxzOyt4GwvOorZp3Igs4AsI9iz9d85lWaIFnuzN6gz9XUKvnHF+lhN3EEA5q6U/rDIMdxHDSFpRfBuKjHteb4/L3zmvwQu2bvcsOIM6b2DkATxHG1DDzxjKU8bqlMy91NgFwW5TyY+VpcAS+MElDVaWI0ETTubJEPdJRm7PQy31IF7rbCT4JC2+tJkEfBJE4AnPlvRjmflFWIy1Umu01duks+Uuv6BA0tPgrmQr2P1MKdvFxzmyMg7rOjJo4WT4tIhK2aA5CIa2Bo80EdLEcr7L+K5a6cFSDleI1kgFtTMafMR+UZeTwHzpDxfzUDnLW49NIiqbczN/tXKZuzMWQisV8B1QD2DfYXe8OYLVqHUKzRie9N599WJkPXNdLPYdjiWPyAfSey/bx/HmneLBGGwDhgyayqGhNVEY7yv7MokTMmZy/yrG5Uk/o+Q4wXi6rPONNNNjttnMlKGu327i+xKdns4PoOzDKus5V6O+2GnXEc1kffwZDJR0/BcmleB/Y87P1us/3bWDtRkIAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var InputContainer = '_1q9emjt7';
export var fontItem = '_1q9emjtb';
export var fontItemContainer = '_1q9emjta';
export var fontLabel = '_1q9emjtc';
export var menu = '_1q9emjt1';
export var menuTrigger = '_1q9emjt2';
export var notFound = '_1q9emjte';
export var searchIcon = '_1q9emjt9';
export var searchInput = '_1q9emjt8';
export var selectedIcon = '_1q9emjtd';
export var settingWrapper = '_1q9emjt0';
export var shapeIndicator = '_1q9emjt6';
export var snapshot = '_1q9emjt5';
export var snapshotContainer = '_1q9emjt3';
export var snapshotTitle = '_1q9emjt4';