import "../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61X227jNhB9z1cQBhZIUNCQL7kpCND/KIJiLI6kiSlSICl7vcX+eyHqYlGWtW6zTwnF4VzOHJ6h/9zjKTVQoGV/rz5PzyZPd+yfO8aib/4PY7qEhNwpZqu3O8Z+3jH2NLP3fLkXdXsvM+deZ86toqsHf94tu7RX3iTRUpuYkcrRkHsbGqy9gSBbSjjVJpIU8lTi99oXSMoUJ4eFjVmCyqGpP++0EWi4AUGVjdm29MYOvzvuT8RMYureJiIzdiTh8tinXy8LUjxHynIXs03UOKosGm5RYuJiprRC76kytnZVaurSKEEIUlnMoi6FVCvHLf3AmB3A3HMOaerr8d+LB39KW3KkVcwMSnB08O4LMBkp7nQZM5/GEKM41wc0HqkdJPvM6EqJUQhvwn29D+HpvwQ44JDUod4XzlS4+PiaL0EWdhJF4K0DSGAKlRyiH/j2XbKYaCXAnPogrAOW4wGVsx3wE8ENZBmpLAh+5ufyMTi0Cfn1C2K15PAkr01bSk+3LIMyZqv1qFlbH3C6mKEn2FktK+e/1mxto3oC8FXLxJ6szbIj6mrMkMcGhAOaVOpjzHISAlV/Kc4bKCWVlqx3npNDbktIsE7waKCcgWYAx9W2loYKMKeQM0//qQOmqTC6DlYoRb9mTXt5RvmEmjX2QoocgQyT6D8OvD9PF/dZWUdpzW7lULlhgfPMW4863a2nIKdEqwHkgfqsxwx58Yl2fHoa8ald39qkmfJG0rxupdmA6mD0/6faFCxaru2NxQ0raWQg0VJCaUci1DuPmdEOHN7z10hgNuliUsYGSrK5iWAvX1Ln13Pak/gwBIucFNdVODhfvw4DhOS9qAxireSJJzlJMR7Tl1ch8SbDOdY2vx2TvFG5BpbuUHR1JA48i3lszxsdYQZTpb9X7UUa8fNpdE2wVe/ZfLCB3hmsO2OdqZLa+n1RwB4buBYfjY1FmTYTC4WX4fdFCtLi4iOG1LWs6a/RYhHcwp3Uyf66EP7gpEQvyjdSrxs2U21oHh8D1BKQyb1/6PGLAw/hQKpfU78bomZfl6imUANFBTSonB/JK8tIpTU9kdUvSTBBWmnT3u7d9jLJiX76c0EGk5YI+sgNHtDYmdmfXm/qBaJ/zCMaLR/b5K7Svsn63Niddk4Xs7PzPF2DrKcFzItICQaVG1fp35L/U/HSm96j14Lnwzm2WYdzrFsPB+F21CPyDgYWq7EGfF5YPDdu/U+TfjK3sSS6ejzUzyjPKR4tt5tBGsfWfhuFqO8v5LLe/xcz//4n+g0AAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1jy7rhfa';
export var collapsedIcon = '_1jy7rhf9';
export var collapsedIconContainer = '_1jy7rhf8';
export var contentContainer = '_1jy7rhfc';
export var draggedOverEffect = '_1jy7rhfe';
export var draggingContainer = '_1jy7rhfd';
export var iconContainer = '_1jy7rhf7';
export var itemContent = '_1jy7rhf5';
export var itemMain = '_1jy7rhf3';
export var itemRenameAnchor = '_1jy7rhf4';
export var itemRoot = '_1jy7rhf2';
export var levelIndent = 'var(--_1jy7rhf0)';
export var linkItemRoot = '_1jy7rhf1';
export var mobileCollapsedIconContainer = '_1jy7rhfi _1jy7rhf8';
export var mobileContentContainer = '_1jy7rhfk _1jy7rhfc';
export var mobileIconContainer = '_1jy7rhfh _1jy7rhf7';
export var mobileItemContent = '_1jy7rhfj _1jy7rhf5';
export var mobileItemMain = '_1jy7rhfg _1jy7rhf3';
export var mobileItemRoot = '_1jy7rhff _1jy7rhf2';
export var postfix = '_1jy7rhf6';