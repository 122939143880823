import "../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61V246bMBB9z1eMti/dB0ckIWTrqF9SVZXBA7hrbGSbTbbV/ntlE3aBkCxIfULy3M6cOTOsf23iUiapjODvCqBiphCKOF1T2EX1+bh6W607l03PhQJrnD5+hKTaOV1RiH0QgMOzI0yKQlHIUDk0g1TbG9UAMi21ofDCzFdCWJ4LhSQks5hpxZl5JcHl0TvnWjlixR8cBYT3sw0+0j+UKIrSUdis9wMcu+U4SlGU0mcjuTZYGN0oTgzy/wNoCyxAmiovhXp+b34Qs/amNq4x1gfWWrSkL0sVhyS1tsIJ7YecWi0bhz6PaRFHxxFjZJuMhLLvs9oJ40pOybxGAVKWPbc8U3CGKVszg8oFkzYcDbkkGRkXD2O7bWffWDTEosTMUVBa4QD3IeC+m9xWj4u1/DnpHZPRAM5TgNO1cDi0LXBha8leKeQSw4P/Ei4MZm2RTMumUt7yu7FO5B6Kcqjcx74ChAUmwmFl+893r8S3gOeq/p0qBaspPLW4J+fxOde9+rf3J1BfG1FNHJHTpWQSDdlNQ7aT4K6kEO9nHbdsEBPdjpmnogvbEnN/L5Lb5ymVDV53FI864gHdNJwlU7uhjTlLN8C3j64uSnLhefxrGUsNp6U2B8K7xKKexEaQZt7SujFITqVwOLpWZHJIhnWWdu0Z50IVFKLu13m5aYZx0dj3H+rSSfRp+sGZY0RYkqIU+ILm+4MzDT78DPxdA/6yO8Sb/ea4evsHs9KSmB8IAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_14hl6bld';
export var authMessage = '_14hl6bl2';
export var authModalContent = '_14hl6bl0';
export var captchaWrapper = '_14hl6bl1';
export var errorMessage = '_14hl6bl3';
export var forgetPasswordButton = '_14hl6bl7';
export var forgetPasswordButtonRow = '_14hl6bl4';
export var linkButton = '_14hl6bl6';
export var resendCountdown = '_14hl6blb';
export var resendCountdownInButton = '_14hl6blc';
export var resendWrapper = '_14hl6bl8';
export var sendMagicLinkButtonRow = '_14hl6bl5';
export var sentMessage = '_14hl6bla';
export var sentRow = '_14hl6bl9';
export var userPlanButton = '_14hl6ble';