import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W62rjOBT+n6c4WygkUAUnTTJTdwplFvYFdmEZlmGRreNEU1kykpxLl7z7IslxFMfJtGV+JTnX7zs35fkFd4WmJRr4d1K/bBfl9h7+GwAUWpX+C4CqaM7tLoXkcQCwHwBY1VVNgmo/eD4POLseEMBqKk2hdJmGr4Ja/DYk09sRmJwKHCbjh8XoJ8kvhUkOQSajKxjnPuZ56A8h7KP6CzAuunXsDzZJktvzYl1KfCXfp7flS96aLAK2H4wPWaj3qZThliuZQsG3yJwVlwZt04GM5i9LrWrJSK6E0imsqR4SQouCSySRulSMimDkc70SLhluOw6NNFh7Oyp5SQOC4yJM5klpoFB6QzUzjyewx86DSCXRE4j8naxjW6qMi2D4EyqC7lCTYN/Ci2JlbykX46YSdJdCIXDr2Qm+lIRbLE0KOUqL2ol/1MbyYkdyJS1KG6veVrcY2D+MWkqKWghico0on26srvHme0BMGeNymUICv/GyUtpSaU8DvKOijW1BGf7pFucvVXV9jtcndDGvM56TDF856mEynk3vILmDZDz/fAeT0xEgBRfCccS0v/k96cdrS2hu+TqAX3PcED/7vk9E0hKPmD73RTOCM/yqrG32rYfLApLx9N4AUoMfQhzleBfgBxcsTbtGSrBhy2l0CfX813XgOoSHixA+fbhwuQ9JyOF3ksLjiWDSFUyDYMOZXR3Wp/X2NFfIlyvb1YUSlFySfv006Om21edU5O6qrldA4H5abVuLJnlrsAEC06QxyNSWGP7qtzFTmqEmmfJnolDSOhV21t7LM2pw1FptGgyzxF8c4cwOuCbjxWO88i4zTGeVz3E8XRoFdfP3vgOv1qgF3ZGKSowPvWe1okxtOr6VqpxPo2xsPWtNGa9NCpNpgKZq63gc783lk3ZtPnzBz4bE9+lsUlpp1LXWP+51a9jBnpxMK+u8DjQzStTWl9iqKoVpQ1WHqK4zH7r24WhcnZcVWZyPy6J3XGbzQFcvuSSZP0+HrkQ5C59zozQjmUb6koL/IE7iN27FLRJT0RxTqDSSjaaV7+sadSHUxgtOvaLwSx/+7Ok8eyOdgqBkV97VZvKJL/msqXFDz8tobZWTLR2e0IQYydhX/A+lLOq/uV39vuKCaZTxSxoC9fhqXKM2TYMcVsY15s3KqQ1p9Je5GUv16dO88sFiAoeV6fTsME/R1N6edGB35B79I5h1SPD+VnTZ5ErUpTwuqq/a001BhcGb72d/mbhrD8E1Smu6O37q2l3vrm+gEAH+8T7ATeMPE/5cIuMUwokBKhkM/SmAL0+wcOMT3rUOoc70xWN5HJJDY+6bfu0H+/8BJntrsPMNAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mxd';
export var confirmModalContainer = '_1ukx6mxi';
export var confirmModalContent = '_1ukx6mxh';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mxc';
export var modalContentWrapper = '_1ukx6mxb';
export var modalDescription = '_1ukx6mxf';
export var modalFooter = '_1ukx6mxg';
export var modalHeader = '_1ukx6mxe';
export var modalOverlay = '_1ukx6mxa';
export var promptModalContent = '_1ukx6mxj';
export var widthVar = 'var(--_1ukx6mx0)';